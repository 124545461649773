import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux/actions";

import * as form from "middleware/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";
import { ID_ACTIVITY_MEDIUM_MODIFY_CHANNELS, ID_ACTIVITY_MEDIUM_READ_CHANNELS } from "util/administration";

const loadChannelsRequest = (middleware, actions) =>
    function* loadChannelsRequestSaga({ id }) {
        const { fingerPrintToken } = yield call(getFpTokenbyDevice, ID_ACTIVITY_MEDIUM_READ_CHANNELS);
        const response = yield call(middleware.loadChannelsRequest, { id, fingerPrintToken });

        if (response.type === "W") {
            yield put(actions.loadChannelsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            yield put(actions.loadChannelsSuccess({ ...response.data.data }));
        }
    };

const mapChannelsFormData = ({ idUser, caps }) => ({
    idUser,
    ...Object.entries(caps).reduce(
        ({ enabledChannels, maxAmounts, capFrequencies }, [channel, { amount, frequency }]) => ({
            enabledChannels: [...enabledChannels, channel],
            maxAmounts: [...maxAmounts, amount],
            capFrequencies: [...capFrequencies, frequency],
        }),
        {
            enabledChannels: [],
            maxAmounts: [],
            capFrequencies: [],
        },
    ),
});

const updateChannelsPreview = (middleware, actions) =>
    function* updateChannelsPreviewSaga({ data, setSubmitting }) {
        const response = yield call(middleware.updateChannelsPreview, mapChannelsFormData(data));

        setSubmitting(false);

        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.medium.modify.channels.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.onSuccess(data.idUser));
            yield put(actions.updateChannelsPreviewSuccess(data.caps, credentialGroups));
        }
    };

const updateChannelsRequest = (middleware, actions) =>
    function* updateChannelsRequestSaga({ data, formikBag }) {
        const { credentials, ...restOfParams } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

        const { fingerPrintToken } = yield call(getFpTokenbyDevice, ID_ACTIVITY_MEDIUM_MODIFY_CHANNELS);
        const response = yield call(middleware.updateChannelsRequest, {
            ...credentialsWithUnderscore,
            ...mapChannelsFormData(restOfParams),
            fingerPrintToken,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            setErrors(response.data.data);
            if (response.data.data.otp === null) {
                yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("administration.limits.channels.update.success"),
                    "success",
                    ["administration"],
                ),
            );
            yield put(routerActions.go(-2));
            yield put(actions.updateChannelsRequestSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_CHANNELS_REQUEST, loadChannelsRequest(middleware, actions)),
    takeLatest(types.UPDATE_CHANNELS_PREVIEW, updateChannelsPreview(middleware, actions)),
    takeLatest(types.UPDATE_CHANNELS_REQUEST, updateChannelsRequest(middleware, actions)),
];

export default sagasCreator;
