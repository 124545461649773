import queryString from "query-string";
import { LOCATION_CHANGE, replace, routerActions } from "react-router-redux";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import b64toBlob from "b64-to-blob";
import { ACTIVITIES_WITHOUT_PREVIEW_STEP } from "constants.js";
import * as form from "middleware/form";
import * as payService from "middleware/payService";
import * as wally from "middleware/wally";
import moment from "moment";
import { selectors as checksSelectors } from "reducers/checks";
import { actions as creditCardsActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as beneficiaryActions } from "reducers/frequentDestination/frequentDestination";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions } from "reducers/products";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as transactionalProfileActions } from "reducers/transactionalProfile";
import { types as templateTypes } from "reducers/template";
import types from "reducers/types/form";
import { actions as wallyActions, selectors as wallySelectors, types as wallyTypes } from "reducers/wally";
import { BENEFICIARY_VALIDATION_PREVIEW_ACTIVITIES } from "util/beneficiaryUtil";
import * as configUtils from "util/config";
import { getDisplay, getMobileOS, isAndroidPlatform, isMobileNativeFunc } from "util/device";
import { downloadMobileFile, downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat, getBeneficiaryName } from "util/form";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";
import { getFpTokenbyDevice, nonTransactionalActivities } from "util/monitor/monitorDbf.utils";
import { TRANSFER_LOCAL_FORM_LIST } from "util/thirdTransaction";
import kuaraVersionNumber from "util/kuaraVersionNumber";
import { EVENT_BY_ACTIVITY_MAP, getGtmPayload, setDataLayer } from "util/tagManager/tagManager.util";
import { readCreditCardList } from "./login";

const administrationTicketRoutes = {
    "administration.simple.modify.permissions.send": (id) => `/administration/simple/permissions/${id}/ticket`,
    "administration.medium.modify.permissions.send": (id) => `/administration/medium/permissions/${id}/ticket`,
    "administration.users.blockunblock.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.delete.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.groups.blockunblock.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.groups.delete.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.medium.modify.channels.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/channels/${id}/ticket`,
    "administration.medium.modify.signature.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signature/${id}/ticket`,
    "administration.signatures.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.delete.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.user.detail.groups.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupsOfUser/${id}/ticket`,
    "administration.users.invite.send": (id) => `/administration/medium/userInvite/${id}/ticket`,
    "administration.advanced.group.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.group.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.restrictions.manage.send": (id) => `/administration/restrictions/manage/${id}/ticket`,
    "administration.restrictions.user.delete.send": (id) => `/administration/restrictions/user/delete/${id}/ticket`,
};
const sagas = [
    takeLatest(LOCATION_CHANGE, readForm),
    takeLatest(types.PREVIEW_FORM_REQUEST, previewForm),
    takeLatest(types.SEND_FORM_REQUEST, sendForm),
    takeLatest(LOCATION_CHANGE, readTransaction),
    takeLatest(LOCATION_CHANGE, readTransactionFromBackoffice),
    takeLatest(types.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    takeLatest(types.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    takeLatest(types.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    takeLatest(types.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    takeLatest(types.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    takeLatest(types.SIGN_TRANSACTION_REQUEST, signTransaction),
    takeLatest(types.READ_TRANSACTION_REQUEST, readTransaction),
    takeLatest(types.SEND_FORM_DATA_FAILURE, logout),
    takeLatest(types.DOWNLOAD_TICKET_REQUEST, downloadTicket),
    takeLatest(types.SHARE_TICKET, shareTicket),
    takeLatest(types.PRE_FORM_REQUEST, preForm),
    takeLatest(types.BANK_DESCRIPTION_REQUEST, getBankDescriptionData),
    takeLatest(types.CONFIRM_MOVEMENT_PENDING_WALLY, confirmMovementPendingWally),
    takeLatest(types.CONFIRM_SUSPEND_CHECK, confirmSuspendCheck),
    takeLatest(types.CONFIRM_PAYMENT_FAST_RECHARGE, confirmPaymentFastRecharge),
    takeLatest(types.ROLE_PAYMENT_FILE_DETAILS_REQUEST, rolePaymentFilePayments),
    takeLatest(types.DOWNLOAD_ROLE_PAYMENT_LIST_REQUEST, downloadRolePaymentList),
];

const activitiesNoTransactional = ["requestTransfers.wally.send"];

export default sagas;

function* readForm({ payload }) {
    const {
        state = {
            shouldLoadForm: true,
        },
        pathname,
        search,
    } = payload;
    const [, route, idForm] = pathname.split("/");

    if (route === "form" && state.shouldLoadForm) {
        const { query: params } = queryString.parseUrl(search);
        const response = yield call(form.readForm, idForm, params);

        if (response.type === "W") {
            yield put({ type: types.READ_FORM_FAILURE, notification: { type: "error", code: response.data.code } });
        } else {
            const { form: formMetadata, formData } = response.data.data;

            yield put({ type: templateTypes.LOAD_TEMPLATE_LIST, idForm });
            yield put({ type: types.READ_FORM_SUCCESS, idForm, formMetadata, formData });
        }
    }
}

function* previewForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, formikBag, gtmKeys } = payload;
    const index = idActivity.lastIndexOf(".send");
    const previewActivity = `${idActivity.substring(0, index)}.preview`;
    const { type, data } = yield call(form.preview, idForm, previewActivity, idTransaction, values);
    const idTransactionToRead = data?.idTransaction || null;
    const { code } = data;
    if (code === "API904W" && idActivity === "pay.2x3.send") {
        yield put(routerActions.replace("/desktop"));
        yield put(notificationActions.showNotification(i18n.get("pay.2x3.not.issuer.allowed"), "error", ["desktop"]));
        return;
    }
    const {
        props: { metadata },
    } = formikBag;
    let needsAdditionalSteptoEdit = false;
    if (metadata && metadata.fieldList) {
        needsAdditionalSteptoEdit = metadata.fieldList.filter((i) => i.idField === "overdraft").length > 0;
    }
    const mode = !needsAdditionalSteptoEdit ? "preview" : "edit-step2";
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        if (data.error) {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            if (data.error && data.error.details) {
                formikBag.setErrors(adjustIdFieldErrors(data.error.details));
            }
            formikBag.setSubmitting(false);
        } else {
            const response = yield call(form.listCredentialsGroupsForTx, idForm, idActivity, idTransactionToRead);

            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values },
                previewData: data,
            });
            yield put(formActions.setData(values));
            formikBag.setSubmitting(false);

            yield put({
                type: types.SET_MODE,
                mode,
                prevMode: "edit",
            });
        }
    } else if (type === "W") {
        if (data.code !== "COR020W" && !data?.data?.snackbar) {
            yield put(notificationActions.showNotification(data.message, "error", ["form"]));
        } else if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
        } else if (data?.data?.snackBarSameSteep) {
            yield put(notificationActions.showNotification(data?.data?.snackBarSameSteep, "error", ["form"]));
        } else if (data?.data["beneficiary.bankCountry"]) {
            yield put(transactionalProfileActions.modalEditShow());
        } else {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
        }

        if (data?.data?.snackbar) {
            yield put(notificationActions.showNotification(data?.data?.snackbar, "error", ["form"]));
        }

        const errors = adjustIdFieldErrors(data.data);
        formikBag.setErrors(errors);
        formikBag.setTouched({ beneficiary: { number: true } });
        formikBag.setSubmitting(false);
    } else {
        const alertConfirmation = data?.data?.alertConfirmation;
        const disclaimerList = data?.data?.disclaimerList;
        const submitDirectly = alertConfirmation?.submit !== undefined ? alertConfirmation?.submit : true;

        // TODO a WARNING here must be treated as an ERROR, right?
        const response = yield call(form.listCredentialsGroupsForTx, idForm, idActivity, idTransactionToRead);

        let valuesDef = { ...values };
        /**
         * Validate save beneficiary
         */
        if (BENEFICIARY_VALIDATION_PREVIEW_ACTIVITIES.includes(previewActivity)) {
            const saveBeneficiary = data?.data?.saveBeneficiary;
            const { beneficiary } = valuesDef;
            const beneficiaryUpdate = beneficiary ? { ...beneficiary, saveBeneficiary } : beneficiary;
            valuesDef = { ...values, beneficiary: beneficiaryUpdate };
            if (data?.data?.achAccountValidationResult) {
                const achAccountValidationResult = data?.data?.achAccountValidationResult;
                yield put(formActions.setAchAccountValidationResult(achAccountValidationResult));
            }

            if (data?.data?.achAccountValidationResult) {
                const {
                    validAccount,
                    message,
                    snackbar,
                    continueToPreview,
                    modal,
                } = data?.data?.achAccountValidationResult;
                const isShowACHModal = modal;
                yield put(formActions.showACHModal(isShowACHModal));

                if (!validAccount && snackbar) {
                    yield put(notificationActions.showNotification(message, "error", ["form"], false));
                    formikBag.setSubmitting(false);
                    if (!continueToPreview) {
                        return;
                    }
                }
            }
        }

        if (disclaimerList && disclaimerList.length > 0) {
            for (let i = 0; i < disclaimerList.length; i++) {
                const disclaimer = disclaimerList[i];
                if (disclaimer && disclaimer.level && disclaimer.message) {
                    yield put(
                        notificationActions.showNotification(disclaimer.message, disclaimer.level, ["form"], false),
                    );
                }
            }
        }

        if (data?.data?.previewDataForm && Object.keys(data?.data?.previewDataForm).length > 0) {
            valuesDef = { ...valuesDef, ...data?.data?.previewDataForm };
        }

        /**
         * Validate alert confirm transaction
         */

        if (alertConfirmation) {
            yield put({
                type: types.PREVIEW_FORM_CONFIRM,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: {
                    idForm,
                    idActivity,
                    idTransaction,
                    values: valuesDef,
                    confirmationFetching: true,
                    gtmKeys,
                },
                previewData: data.data,
                alertConfirmation,
                submitDirectly,
                formikBagConfirmation: formikBag,
            });
            yield put({
                type: types.SET_MODE,
                mode: "edit",
                prevMode: "",
            });
        } else {
            const { fingerPrintToken } = yield call(getFpTokenbyDevice, idActivity);
            let _valuesDef = { ...valuesDef, fingerPrintToken };

            if (TRANSFER_LOCAL_FORM_LIST.some((item) => `${item}.send` === idActivity)) {
                const creditAccountInfo = data?.data?._creditAccountInfo;
                if (creditAccountInfo) {
                    _valuesDef = { ..._valuesDef, _creditAccountInfo: creditAccountInfo };
                }
            }

            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values: _valuesDef, gtmKeys },
                previewData: data.data,
            });

            yield put({
                type: types.SET_MODE,
                mode,
                prevMode: "edit",
            });
        }

        yield put(formActions.setData(values));
        formikBag.setSubmitting(false);
    }
}

function* downloadRefLetter(contentType, content, fileName) {
    if (!contentType || !content) {
        yield;
        return;
    }

    const contentTypeMap = new Map([
        ["pdf", "application/pdf"],
        ["xls", "application/vnd.ms-excel"],
    ]);

    const contenTypeDef = contentTypeMap.get(contentType);
    if (!contenTypeDef || contenTypeDef === "") {
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        const fileBlob = b64toBlob(content, contenTypeDef);
        downloadMobileFile(fileBlob, fileName || "referenceLetter", contenTypeDef);
        yield;
        return;
    }
    downloadPdf(fileName, content);
}

function* validateCreditCardCacheServicePayment(activityId, dataValues) {
    if (!activityId || activityId !== "transfers.payService.send") {
        yield;
        return;
    }

    if (!dataValues?.creditCard || !dataValues?.typeAccount || dataValues.typeAccount !== "creditCard") {
        yield;
        return;
    }

    yield call(readCreditCardList, dataValues.creditCard);
}

function* validateCreditCardCache(activityId, dataValues, values) {
    if (
        activityId === "creditCard.recharge.creditCardLocal.send" ||
        activityId === "creditCard.payment.creditCardLocal.send" ||
        activityId === "creditCard.cashAdvance.send"
    ) {
        // invalidate cache for creditcards
        yield put(creditCardsActions.invalidateCache());
    }

    if (activityId === "creditCard.payment.creditCardLocal.send") {
        yield call(readCreditCardList, dataValues?.creditCard);
    }

    if (activityId !== "beneficiary.create.send" && values?.beneficiary) {
        yield put(beneficiaryActions.setBeneficiarySelected(null));
    }

    yield call(validateCreditCardCacheServicePayment, activityId, dataValues);
}

function* showErrorNotificationKuara(code) {
    const mapError = new Map([
        ["API600W", "transfer.kuara.error.not.kuara.destiny"],
        ["COR110W", "transfer.kuara.error.not.amount.daily"],
        ["COR067W", "transfer.kuara.error.not.amount.daily"],
    ]);

    const error = code ? mapError.get(code) || "transfer.kuara.error.general" : "transfer.kuara.error.general";
    yield put(notificationActions.showNotification(i18n.get(error), "error", ["form"], false));
    yield put({
        type: types.SEND_FORM_DATA_FAILURE,
        code,
    });
}

function* sendDataLayerForm(dataValues, activityId, gtmKeys) {
    try {
        const eventName = EVENT_BY_ACTIVITY_MAP.get(activityId);
        if (!eventName) {
            yield;
            return;
        }

        const payload = getGtmPayload(dataValues, gtmKeys);
        yield call(setDataLayer, { ...payload, event: eventName });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

function* sendForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, credentials, formikBag, gtmKeys } = payload;
    let dataValues = values;
    const { inputManually } = dataValues;
    if (idActivity === "role.payment.send" || idActivity === "suppliers.payment.send") {
        const previewData = yield select((state) => formSelectors.getPreviewData(state));
        if (dataValues.uploadBy && dataValues.uploadBy === "file") {
            dataValues.inputManually = [];
            dataValues.motive = previewData?.motive;
        } else {
            const files = previewData?.file;
            dataValues.file = [files];
        }
    }

    const fullOwnerName = yield select((state) => sessionSelectors.getUserFullName(state));
    const beneficiaryDescription = getBeneficiaryName(values, idActivity, fullOwnerName);
    if (beneficiaryDescription) {
        dataValues = { ...values, beneficiaryDescription };
    }
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        const response = yield call(form.send, idForm, idActivity, idTransaction, dataValues, credentials);
        const { data, status } = response;
        if (status === 200 && typeof data === "string") {
            yield put(routerActions.replace(`/transaction/${data}`));
        } else {
            yield put({
                type: types.SEND_FORM_DATA_FAILURE,
                code: "",
            });
            if (data && data.error && data.error.message) {
                if (data.error.details && data.error.details.transactionId) {
                    yield put(routerActions.replace(`/transaction/${data.error.details.transactionId}`));
                } else {
                    yield put(notificationActions.showNotification(data.error.message, "error", ["form"]));
                }
            } else {
                yield put(notificationActions.showNotification(data[Object.keys(data)[0]], "error", ["form"]));
            }
        }
        formikBag.setSubmitting(false);
    } else {
        if (nonTransactionalActivities.includes(idActivity)) {
            const { fingerPrintToken } = yield call(getFpTokenbyDevice, idActivity);
            dataValues.fingerPrintToken = fingerPrintToken;
        }

        yield fork(sendDataLayerForm, dataValues, idActivity, gtmKeys);
        const { data, type } = yield call(form.send, idForm, idActivity, idTransaction, dataValues, credentials);

        if (type === "W") {
            const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

            if (data.data?.amount && idActivity === "transfers.foreign.send") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("forms.transfers.foreign.estimatedAmount.insufficient"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
                yield put({
                    type: types.SEND_FORM_DATA_INSUFFICIENT_FOUND,
                });
                formikBag.setSubmitting(false);
                return;
            }

            if (hasIncorrectCredentials) {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
                yield put({
                    type: types.SEND_FORM_CREDENTIAL_FAILURE,
                    code: data.code,
                });
                if (idActivity === "transfers.approve.wally.send") {
                    yield put(notificationActions.showNotification(data.data.amount, "error", ["form"], false));
                }
            } else if (data.data.snackBarSameSteep) {
                yield put(notificationActions.showNotification(data.data.snackBarSameSteep, "error", ["form"], false));
            } else if (data.code === "API606W" && idActivity === "requests.checkBooks.send") {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: data.code,
                    errors: { debitAccount: data?.message },
                });
            } else if (idActivity === "transfers.kuara.send") {
                yield call(showErrorNotificationKuara, data?.code);
            } else if (data.code === "BAK001W" && idActivity === "fixed.term.deposit.create.send") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("fixed.term.deposit.backend.error.message"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
            } else {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: data.code,
                });
                yield put(notificationActions.showNotification(data.message, "error", ["form"], false));
                if (data?.data?.snackbar) {
                    yield put(notificationActions.showNotification(data?.data?.snackbar, "error", ["form"]));
                }
            }

            formikBag.setSubmitting(false);
        } else {
            if (idActivity === "reference.letter.send") {
                const { contentType, content, fileName, noValidWarning, noValidError } = data.data;
                if (noValidWarning) {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("reference.letter.warning.someAccountsAreNotValid", null, {
                                MONTHS: configUtils.getInteger("form.reference.letter.field.account.monthToBeValid"),
                            }),
                            "warning",
                            ["desktop"],
                        ),
                    );
                }
                if (noValidError) {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("reference.letter.warning.someAccountsAreNotValidMonths"),
                            "warning",
                            ["desktop"],
                        ),
                    );
                }
                if (!noValidWarning && !noValidError) {
                    yield call(downloadRefLetter, contentType, content, fileName);
                }
                yield put(replace("/desktop"));
            } else if (idActivity === "beneficiary.create.send" || idActivity === "beneficiary.update.send") {
                if (values?.referencePage) {
                    yield put(beneficiaryActions.setBeneficiarySelected(values?.beneficiary));
                    yield put(replace(values.referencePage));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(idActivity.replace("send", "success")),
                            "success",
                            [values.referencePage],
                            true,
                            1000,
                        ),
                    );
                } else {
                    yield put(replace("/frequentDestination"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(idActivity.replace("send", "success")),
                            "success",
                            ["frequentDestination", "desktop", "menu"],
                            true,
                            1000,
                        ),
                    );
                }
            } else if (idActivity === "token.pin.send") {
                yield put(
                    notificationActions.showNotification(i18n.get("token.pin.create.success"), "success", [
                        "settings/authenticatorHandler",
                        "/desktop",
                    ]),
                );
                yield put(replace("/settings/authenticatorHandler"));
            } else {
                let transaction;
                let usesJointAccount = false;
                if (activitiesNoTransactional.includes(idActivity)) {
                    transaction = {
                        idActivity,
                        data: dataValues,
                        idTransactionStatus: "FINISHED",
                    };
                } else {
                    const tid = data?.idTransaction || idTransaction;
                    const transactionResponse = yield call(form.readTransaction, tid);
                    transaction = transactionResponse.data.data?.transaction;
                    usesJointAccount = transactionResponse.data.data?.usesJointAccount;
                }

                if (idActivity === "report.renew.card.send" || idActivity === "report.replace.card.send") {
                    yield put(productActions.syncEnviromentProduct(false, null, false));
                    yield call(readCreditCardList, dataValues?.productId);
                }

                if (
                    (idActivity === "role.payment.send" || idActivity === "suppliers.payment.send") &&
                    dataValues.uploadBy &&
                    dataValues.uploadBy === "file"
                ) {
                    transaction.data.inputManually = inputManually;
                }

                yield put({
                    type: types.SEND_FORM_SUCCESS,
                    transaction,
                    usesJointAccount,
                });

                yield call(validateCreditCardCache, idActivity, dataValues, values);
            }

            if (idActivity === "transfers.wally.send" || idActivity === "transfers.approve.wally.send") {
                if (kuaraVersionNumber(2)) {
                    return;
                }

                yield put(wallyActions.userWallyRequest());
                const responseWally = yield call(wally.getPendingTransactionsWallyQuantity);
                if (responseWally.status !== 304 && responseWally.type === "I") {
                    yield put({
                        type: wallyTypes.REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS,
                        pendingTransactionsWallyQuantity: responseWally.data.data.pendingTransactionsWallyQuantity,
                    });
                }
            }

            if (idActivity === "transfers.foreign.send") {
                const { profileEdited } = data.data;
                if (profileEdited) {
                    const msg =
                        dataValues?.showApprovalTransfer === "1"
                            ? i18n.get("forms.transfers.foreign.profile.created.success")
                            : i18n.get("forms.transfers.foreign.profile.edited.success");
                    yield put(notificationActions.showNotification(msg, "success", ["form"], false));
                }
            }

            if (idActivity === "transfers.payService.send") {
                // guardo pago de servicio
                if (payload.values.savePayment) {
                    const responseCreatePayService = yield call(
                        payService.createPayService,
                        payload.values.paymentUser,
                    );
                    if (responseCreatePayService && responseCreatePayService.status === 200) {
                        yield put(payServiceActions.listPayServiceRequest(true));
                    }
                }
                // edito cuando es recarga rapida
                if (
                    payload.values?.savePayment === false &&
                    payload.values?.isFastRecharge &&
                    !payload.values.isLinkFastRecharge
                ) {
                    const responseUpdatePayService = yield call(
                        payService.updatePayService,
                        payload.values.paymentUser,
                    );
                    if (responseUpdatePayService && responseUpdatePayService.status === 200) {
                        yield put(payServiceActions.listPayServiceRequest(true));
                    }
                }
            }

            formikBag.setSubmitting(false);
            const notification = data?.data?.notification;
            if (notification) {
                yield put(notificationActions.showNotification(notification, "success", ["form"], false));
            }
        }

        const pathRedirectToWally = yield select((state) => wallySelectors.getPathRedirectToWally(state));
        if (values.isFromWally && pathRedirectToWally) {
            yield put(routerActions.replace(pathRedirectToWally));
            yield put(wallyActions.userWallyRequest());
            const transactionType = type === "W" ? "error" : "success";
            yield put(
                notificationActions.showNotification(
                    i18n.get(`forms.transferInternal.toWally.${transactionType}.notification`),
                    transactionType,
                    ["pendingTransactionWally", "form"],
                    false,
                ),
            );
        }
        if (payload.values.beneficiary?.saveBeneficiary) {
            if (data?.data?.beneficiarySaved) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transfer.beneficiary.succesfully.disclaimer"),
                        "success",
                        ["form"],
                        false,
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transfer.create.beneficiary.error.disclaimer"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
            }
        }
    }
}

function* readTransaction({ payload, isCanceledTransaction = false }) {
    const [, route, idTransaction, referenceNumber] = payload.pathname.split("/");
    if (route === "transaction") {
        let transactionResponse;
        if (idTransaction === "historic" && referenceNumber) {
            const detailTransaction = yield call(form.readTransactionHistoric, referenceNumber);
            if (detailTransaction.type === "W") {
                yield put({
                    type: types.READ_TRANSACTION_FAILURE,
                    notification: { type: "error", code: transactionResponse.data.code },
                });
            } else {
                const { transactionData } = detailTransaction?.data?.data;
                const transactionStatus = transactionData.TrnStatus === "FALLIDA" ? "FAILED" : "FINISHED";
                const currentTransactionWithData = {
                    idTransactionStatus: transactionStatus,
                    idTransaction: transactionData?.RegistryNumber,
                    idActivity: "historic.transaction",
                    data: { ...transactionData },
                };

                yield put({
                    type: types.READ_TRANSACTION_SUCCESS,
                    idForm: currentTransactionWithData.idForm,
                    transaction: currentTransactionWithData,
                    childrenTransactions: null,
                    parentTransaction: null,
                    formMetadata: { fieldList: [], formNameMap: [] },
                });
            }
        } else {
            transactionResponse = yield call(form.readTransaction, idTransaction);
            if (transactionResponse.type === "W") {
                yield put({
                    type: types.READ_TRANSACTION_FAILURE,
                    notification: { type: "error", code: transactionResponse.data.code },
                });
            } else {
                const {
                    transaction: { idForm, formVersion, idActivity },
                    usesJointAccount,
                } = transactionResponse.data.data;

                if (idForm === null) {
                    yield readTransactionFormIsNull(
                        idActivity,
                        transactionResponse,
                        idTransaction,
                        usesJointAccount,
                        isCanceledTransaction,
                    );
                } else {
                    const formResponse = yield call(form.readForm, idForm, {
                        idTransactionToRead: idTransaction,
                        formVersion,
                    });

                    if (formResponse.type === "W") {
                        yield put({
                            type: types.READ_FORM_FAILURE,
                            notification: { type: "error", code: formResponse.data.code },
                        });
                    } else {
                        yield requestReadTransactionSuccess(transactionResponse, formResponse, usesJointAccount);
                    }
                }
            }
        }
    } else if (route === "transactionWally") {
        const dataTransactionWally = payload.state;
        const currentTransactionWithData = {
            idTransactionStatus: "FINISHED",
            idTransaction: dataTransactionWally?.transactionCode,
            idActivity: dataTransactionWally?.idActivity,
            data: { ...dataTransactionWally },
        };

        yield put({
            type: types.READ_TRANSACTION_SUCCESS,
            idForm: currentTransactionWithData.idForm,
            transaction: currentTransactionWithData,
            childrenTransactions: null,
            parentTransaction: null,
            formMetadata: { fieldList: [], formNameMap: [] },
        });
    }
}

function* requestReadTransactionSuccess(
    transactionResponse,
    formResponse = null,
    usesJointAccount = false,
    isCanceledTransaction = false,
) {
    const { children, parent } = transactionResponse.data.data;
    let { transaction } = transactionResponse.data.data;
    transaction = { ...transaction, dispatcher: transactionResponse.data.data.dispatcher };
    yield put({
        type: types.READ_TRANSACTION_SUCCESS,
        idForm: transaction.idForm,
        transaction,
        childrenTransactions: children,
        parentTransaction: parent,
        formMetadata: formResponse?.data?.data?.form || { fieldList: [], formNameMap: [] },
        usesJointAccount,
    });
    if (isCanceledTransaction) {
        const titleKind = getTransactionKind(transaction?.idActivity);
        yield put(
            notificationActions.showNotification(
                i18n.get(`forms.cancelTransaction.${titleKind}.confirmationMessage`),
                "success",
                ["form", "desktop"],
            ),
        );
    }
}

function* readTransactionFormIsNull(
    idActivity,
    transactionResponse,
    idTransaction,
    usesJointAccount,
    isCanceledTransaction = false,
) {
    if (idActivity in administrationTicketRoutes) {
        const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
        yield put(routerActions.replace(administrationTicketRoutes[idActivity](idTransaction, administrationScheme)));
    } else {
        yield requestReadTransactionSuccess(transactionResponse, null, usesJointAccount, isCanceledTransaction);
    }
}

function* readTransactionFromBackoffice({ payload }) {
    if (payload.pathname === "/forms/backoffice/ticket") {
        const { query } = queryString.parseUrl(payload.search);
        const exchangeToken = query._exchangeToken;

        const { data } = yield call(form.readTransactionFromBackoffice, exchangeToken);

        yield put({
            type: types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
            idForm: data.data.transaction.idForm,
            data: data.data.transactionData,
            formMetadata: data.data.form,
            transaction: data.data.transaction,
        });
    }
}

function* saveDraftTransaction({ payload }) {
    const { idForm, data, idActivityDraft, idTransaction } = payload;
    const response = yield call(form.saveDraft, idForm, data, idActivityDraft, idTransaction);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("forms.saveDraft.errorMessage"), "error", ["form"]));
        yield put({ type: types.SAVE_DRAFT_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveDraft.confirmationMessage");
        yield put(routerActions.push("/desktop"));
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["accounts", "desktop"]));
        yield put({ type: types.SAVE_DRAFT_SUCCESS, idForm, data: response.data.data });
    }
}

function* cancelTransactionPre({ payload }) {
    const { idActivity, idForm } = payload;
    const { type, data } = yield call(form.listCredentialsGroups, idForm, idActivity);

    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.pre.error"), "error", ["form"]),
        );
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_ERROR,
        });
    } else {
        const { groups } = data.data;

        yield put({
            type: types.CANCEL_TRANSACTION_PRE_SUCCESS,
            credentialsGroups: groups,
        });
    }
}

function* cancelTransaction({ payload }) {
    const { credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    const { idTransaction, idForm, scheduled } = formikBag.props;
    const {
        data: { data },
        type,
    } = yield call(
        form.cancelTransaction,
        idTransaction,
        {
            ...credentialsWithUnderscore,
        },
        idForm,
    );
    if (type === "W") {
        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", [
                    "form",
                ]),
            );
        }
    } else {
        yield put({
            type: types.CANCEL_TRANSACTION_SUCCESS,
        });
        yield put(
            formActions.readTransaction(
                window.location.pathname.includes("/transaction")
                    ? window.location
                    : { pathname: `/transaction/${idTransaction}` },
                scheduled,
            ),
        );
    }

    formikBag.setSubmitting(false);
}

function* downloadTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_TICKET_SUCCESS });
    }
}

function* shareTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    const fileName = "Transaction Ticket";
    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        let contentData = `data:text/pdf;base64,${data.data.content}`;
        if (getMobileOS(getDisplay()) === "iOS") {
            contentData = `data:text/pdf:${fileName}'.pdf;base64,${data.data.content}`;
        }
        let options = {
            files: [contentData],
        };

        if (isMobileNativeFunc() && isAndroidPlatform()) {
            options = { ...options, message: fileName, subject: fileName };
        }

        window.plugins.socialsharing.shareWithOptions(options, null, null);
    }
}

function* modifyTransaction({ idTransaction }) {
    const response = yield call(form.moveToDraftTransaction, idTransaction);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.modifyTransaction.errorMessage"), "error", ["form"]),
        );
        yield put({ type: types.CANCEL_TRANSACTION_FAILURE });
    } else {
        yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
    }
}

function* signTransactionPreview({ payload }) {
    const { idForm, idActivity, idTransaction, ticketData, values } = payload;
    const idTransactionToRead = idTransaction;
    const credentialsResponse = yield call(form.listCredentialsGroupsForTx, idForm, idActivity, idTransactionToRead);
    const objectData = {
        type: types.SIGN_TRANSACTION_PREVIEW_SUCCESS,
        idForm,
        credentialsGroups: credentialsResponse.data.data.groups,
        submitAction: formActions.signTransaction,
        submitActionParams: { idForm, idActivity, idTransaction, values },
        ticketData,
        mode: "preview",
    };

    if (ACTIVITIES_WITHOUT_PREVIEW_STEP.indexOf(idActivity) === -1) {
        const { type, data } = yield call(
            form.signPreview,
            idForm,
            idActivity.replace(".send", ".preview"),
            idTransaction,
        );

        if (type === "W") {
            yield put({
                type: types.SIGN_TRANSACTION_PREVIEW_FAILURE,
                code: data.code,
            });
            if (Object.keys(data.data).length) {
                yield put(notificationActions.showNotification(Object.values(data.data)[0], "error", ["form"]));
            } else {
                yield put(notificationActions.showNotification(data.message, "error", ["form"]));
            }
        } else if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            yield put({ ...objectData, previewData: data });
        } else {
            yield put({ ...objectData, previewData: data.data });
        }
    } else {
        yield put(objectData);
    }
}

function* signTransaction({ payload }) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = payload;
    const { data, type } = yield call(form.sign, idForm, idActivity, idTransaction, credentials);

    if (type === "W") {
        const hasIncorrectCredentials = Object.keys(credentials?.credentials).some((key) => data.data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(adjustIdFieldErrors(data.data));
            yield put({
                type: types.SEND_FORM_CREDENTIAL_FAILURE,
                code: data.code,
            });
        } else {
            const message = data.data?.amount || data.message;
            yield put({
                type: types.SIGN_TRANSACTION_PREVIEW_FAILURE,
                code: data.code,
            });
            yield put(notificationActions.showNotification(message, "error", ["form"]));
        }

        formikBag.setSubmitting(false);
    } else {
        const transactionResponse = yield call(form.readTransaction, idTransaction);
        const { transaction, usesJointAccount } = transactionResponse.data.data;
        yield put({
            type: types.SEND_FORM_SUCCESS,
            transaction,
            idTransaction,
            usesJointAccount,
        });
        formikBag.setSubmitting(false);
    }
}

// function* listTransactionLinesRequest({ payload }) {
//     const response = yield call(file.listTransactionLines, payload);
//     if (response.type === "W") {
//         yield put(transactionLinesActions.listTransactionLinesFailure());
//     } else {
//         yield put(transactionLinesActions.listTransactionLinesSuccess(response.data.data));
//     }
// }

function* logout({ code }) {
    if (code === "API010W") {
        yield put(sessionActions.logout());
        yield put(notificationActions.showNotification(i18n.get("returnCode.API010W"), "error", ["externalLayout"]));
    }
}
function* preForm({ values }) {
    const { idActivity, requestData } = values;
    const { data } = yield call(form.pre, idActivity, requestData);
    const { code } = data;
    if (code === "API904W" && idActivity === "pay.2x3.pre") {
        yield put(routerActions.replace("/desktop"));
        yield put(notificationActions.showNotification(i18n.get("pay.2x3.not.issuer.allowed"), "error", ["desktop"]));
        return;
    }
    if (data.error) {
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
    } else {
        const notification = data.data?.notification;
        if (notification) {
            yield put(notificationActions.showNotification(notification, "warning", ["form"], false));
        }
        yield put(formActions.preFormSuccess(null, data.data));
    }
}

function* getBankDescriptionData({ codeBank, codeBankType, idComponent, setFieldValue }) {
    const response = yield call(form.getBankDescriptionData, codeBank, codeBankType);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({
            type: types.BANK_DESCRIPTION_FAILURE,
        });
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API532W") {
                yield put(
                    notificationActions.showNotification(i18n.get("bank.description.notfound"), "error", [
                        "form",
                        "menu",
                        "createFrequentDestination",
                        "frequentDestination.detailModifyDelete",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]),
                );

                yield put(routerActions.replace("/desktop"));
            }
            yield put({
                type: types.BANK_DESCRIPTION_FAILURE,
            });
        } else {
            const responseBank = response.data?.data?.bankDescriptionData;
            yield put({
                type: types.BANK_DESCRIPTION_SUCCESS,
                payload: responseBank ? { ...responseBank, idComponent, setFieldValue } : responseBank,
            });
        }
    }
}

function* confirmMovementPendingWally({ idActivity }) {
    const accountWally = yield select((state) => wallySelectors.getAccountWally(state));
    const movement = yield select((state) => wallySelectors.getSelectedBeneficiary(state));
    const values = {
        debitAccountId: accountWally?.idProduct,
        creditAcctId: movement.accountId,
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
        },
        amount: movement.amount,
        creditReference: movement.creditReference,
        transactionDate: movement.transactionDate,
        xferId: movement.transactionXFerId,
        beneficiaryFullName: movement.fullName,
    };

    const response = yield call(form.listCredentialsGroups, null, idActivity);

    yield put({
        type: types.PREVIEW_FORM_SUCCESS,
        idForm: undefined,
        credentialsGroups: response.data.data.groups,
        submitAction: formActions.sendForm,
        submitActionParams: {
            idForm: undefined,
            idActivity,
            idTransaction: undefined,
            values,
        },
        previewData: { ...values },
    });

    yield put({
        type: types.SET_MODE,
        mode: "preview",
        prevMode: "edit",
    });

    yield put(formActions.setData({ ...values }));
}

function* confirmSuspendCheck({ idActivity }) {
    const dataCheckToSuspend = yield select((state) => checksSelectors.getDataCheckToSuspend(state));

    const activeEnvironment = yield select((state) => sessionSelectors.getActiveEnvironment(state));
    const loggedUser = yield select((state) => sessionSelectors.getUser(state));

    const commissionAmount =
        configUtils.get(`checks.suspend.commission.amount.${activeEnvironment?.type}.${loggedUser?.bankType}`) || "0";

    const values = {
        ...dataCheckToSuspend,
        commissionTotalAmount: commissionAmount,
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
            transactionDate: moment(),
        },
    };

    const response = yield call(form.listCredentialsGroups, null, idActivity);

    yield put({
        type: types.PREVIEW_FORM_SUCCESS,
        idForm: undefined,
        credentialsGroups: response.data.data.groups,
        submitAction: formActions.sendForm,
        submitActionParams: {
            idForm: undefined,
            idActivity,
            idTransaction: undefined,
            values,
        },
        previewData: { ...values },
    });

    yield put({
        type: types.SET_MODE,
        mode: "preview",
        prevMode: "edit",
    });

    yield put(formActions.setData({ ...values }));
}

function* confirmPaymentFastRecharge({ idActivity }) {
    const preData = yield select((state) => formSelectors.getPreData(state));
    const creditCards = yield select((state) => creditCardSelectors.getList(state));
    const queryBill = yield select((state) => payServiceSelectors.getQueryBill(state));
    const paymentUser = queryBill?.paymentUser;
    const accountSelected = preData?.debitAccountList?.filter((el) => el.idProduct === paymentUser?.idProduct);
    const creditCardSelected = creditCards?.filter((el) => el.idProduct === paymentUser?.idProduct);
    const tax = queryBill?.biller.hasTax ? queryBill?.biller.tax : 0;
    const totalTax = (tax * paymentUser.amountQuantity) / 100;
    let isDataCharged = false;

    const values = {
        SubTotal: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity },
        TotalTax: { currency: paymentUser.amountCurrency, quantity: totalTax },
        TotalPmt: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity },
        TotalBalance: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity + totalTax },
        amount: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity + totalTax },
        typeAccount: paymentUser?.productType,
        paymentUser: {
            ...paymentUser,
            billInqRq: JSON.stringify(paymentUser.billInqRq),
            pmtUtilAddRq: JSON.stringify(paymentUser.pmtUtilAddRq),
        },
        biller: queryBill?.biller,
        savePayment: false,
        isFastRecharge: false,
        isLinkFastRecharge: true,
        pmtUtilAddRq: JSON.stringify(paymentUser.pmtUtilAddRq),
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
            transactionDate: moment(),
            valueDate: moment(),
        },
    };
    if (paymentUser?.productType === "debitAccount") {
        values.creditCard = null;
        values.creditCardData = null;
        values.debitAccount = paymentUser?.idProduct;
        if (accountSelected.length > 0) {
            values.debitAccountData = accountSelected[0];
            isDataCharged = true;
        }
    } else {
        values.creditCard = paymentUser?.idProduct;
        values.debitAccount = null;
        values.debitAccountData = null;
        if (creditCardSelected.length > 0) {
            values.creditCardData = creditCardSelected[0];
            isDataCharged = true;
        }
    }

    if (isDataCharged) {
        const response = yield call(form.listCredentialsGroups, null, idActivity);

        yield put({
            type: types.PREVIEW_FORM_SUCCESS,
            idForm: undefined,
            credentialsGroups: response.data.data.groups,
            submitAction: formActions.sendForm,
            submitActionParams: {
                idForm: undefined,
                idActivity,
                idTransaction: undefined,
                values,
            },
            previewData: { ...values },
        });

        yield put({
            type: types.SET_MODE,
            mode: "preview",
            prevMode: "edit",
        });

        yield put(formActions.setData({ ...values }));
    }
    yield put(payServiceActions.isFetchingFastRecharge(false));
}

function* rolePaymentFilePayments({ idTransaction }) {
    const response = yield call(form.rolePaymentFilePayments, idTransaction);

    const { type, data } = response;
    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));

        yield put(routerActions.replace("/desktop"));

        yield put({
            type: types.ROLE_PAYMENT_FILE_DETAILS_FAILURE,
        });
    } else {
        yield put({
            type: types.ROLE_PAYMENT_FILE_DETAILS_SUCCESS,
            paymentList: data.data.paymentList,
            showExtraData: data.data.showExtraData,
        });
    }
}

function* downloadRolePaymentList({ contentType, paymentList, idTransaction, textSearch }) {
    const response = yield call(form.downloadRolePaymentList, contentType, paymentList, idTransaction, textSearch);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.DOWNLOAD_ROLE_PAYMENT_LIST_FAILURE });
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API547W") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transactions.download.file.transactions.notfound"),
                        "error",
                        ["pendingTransaction", "menu", "transactions"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "pendingTransaction",
                        "menu",
                        "transactions",
                    ]),
                );
            }
            yield put({ type: types.DOWNLOAD_ROLE_PAYMENT_LIST_FAILURE });
        } else {
            const { fileName, content } = response.data.data;
            if (fileName && content && contentType) {
                if (isMobileNativeFunc()) {
                    const fileBlob = b64toBlob(content, contentType);
                    downloadMobileFile(fileBlob, fileName, contentType);
                } else if (contentType === "application/pdf") {
                    downloadPdf(fileName, content);
                } else if (contentType === "application/vnd.ms-excel") {
                    downloadXls(fileName, content);
                }
                // yield put(actions.downloadListSuccess());
                yield put({ type: types.DOWNLOAD_ROLE_PAYMENT_LIST_SUCCESS });
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]),
                );
                yield put({ type: types.DOWNLOAD_ROLE_PAYMENT_LIST_FAILURE });
            }
        }
    }
}
