import { format } from "date-fns";
import Box from "pages/_components/Box";
import InfoCard from "pages/_components/InfoCard";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import { bool, shape } from "prop-types";
import React, { useMemo } from "react";
import Col from "react-bootstrap/lib/Col";
import Slider from "react-slick";

const DetailHeadInfo = ({ isDesktop, product }) => {
    const nextDueDate = product.nextDueDate ? format(product.nextDueDate, "DD/MM/YYYY") : null;
    const startDate = product.constitutedDate ? format(product.constitutedDate, "DD/MM/YYYY") : null;
    const lastPaymentDay = product.lastPaymentDay ? format(product.lastPaymentDay, "DD/MM/YYYY") : null;

    const detailArray = useMemo(() => {
        const rawArray = [
            {
                title: "loan.detail.due.date.label",
                message: nextDueDate,
            },
            {
                title: "loan.detail.tax.label",
                tooltip: "loan.detail.tax.tooltip",
                message: product?.rate ? `${product.rate}%` : "",
            },
            {
                title: "loan.detail.initial.amount.label",
                currency: product?.currency,
                quantity: product?.totalAmount,
            },
        ];

        if (product?.paymentType && product.paymentType !== "") {
            rawArray.push({
                title: "loan.detail.payment.label",
                message: product?.paymentType,
            });
        }
        if (lastPaymentDay && lastPaymentDay !== "") {
            rawArray.push({
                title: "loan.detail.last.payp.date.label",
                message: lastPaymentDay,
            });
        }

        return rawArray;
    }, [nextDueDate, product, lastPaymentDay]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        arrows: false,
    };

    return (
        <Box className="detail-head-info pt-0">
            <Row className="px-0">
                {isDesktop ? (
                    <Col xs={12} md={4} className="height-auto">
                        <RemarkableProductInfo
                            currency={product.currency}
                            quantity={product.paidAmount}
                            status={product.status}
                            startDate={startDate}
                            isDesktop={isDesktop}
                            productType={product.productType}
                            completed={product.paidPercentage}
                            showSwitch={false}
                            tooltipText="deposit.detail.fields.open.amount.label"
                            icon={product.categoryIdIcon}
                        />
                    </Col>
                ) : null}

                <Col xs={12} md={8} className="full-height">
                    {isDesktop ? (
                        <Row gapX="3" gapY="3" className="full-height">
                            {detailArray.map((el) => {
                                let elementRender = null;
                                if (el.title) {
                                    elementRender = (
                                        <Col xs={6} md={4} className="height-auto" key={el.quantity + el.title}>
                                            <InfoCard
                                                title={el.title}
                                                {...(el.date && { date: el.date })}
                                                {...(el.currency && { currency: el.currency, quantity: el.quantity })}
                                                {...(el.message && { message: el.message })}
                                                tooltip={el.tooltip}
                                                isDesktop={isDesktop}
                                            />
                                        </Col>
                                    );
                                }
                                return elementRender;
                            })}
                        </Row>
                    ) : (
                        <div className="slick-slider-wrapper">
                            <Slider {...settings}>
                                {detailArray
                                    .reduce((acc, el, i) => {
                                        const index = Math.floor(i / 4);

                                        if (!acc[index]) {
                                            acc[index] = [];
                                        }

                                        acc[index].push(el);

                                        return acc;
                                    }, [])
                                    .map((el) => (
                                        <Row className="d-grid-i" gapX="5" gapY="3" key={el[0].quantity + el[0].title}>
                                            {el.map((loanOne, idx) => {
                                                let elementRender = null;
                                                if (loanOne.title) {
                                                    elementRender = (
                                                        <Col
                                                            xs={6}
                                                            className="height-auto"
                                                            key={loanOne.quantity + loanOne.title}>
                                                            <InfoCard
                                                                title={loanOne.title}
                                                                {...(loanOne.date && { date: loanOne.date })}
                                                                {...(loanOne.currency && {
                                                                    currency: loanOne.currency,
                                                                    quantity: loanOne.quantity,
                                                                })}
                                                                {...(loanOne.message && {
                                                                    message: loanOne.message,
                                                                })}
                                                                tooltipPosition={
                                                                    idx % 2 ? "bottom-right" : "bottom-left"
                                                                }
                                                                tooltip={loanOne.tooltip}
                                                                plus={loanOne.plus}
                                                            />
                                                        </Col>
                                                    );
                                                }
                                                return elementRender;
                                            })}
                                        </Row>
                                    ))}
                            </Slider>
                        </div>
                    )}
                </Col>
            </Row>
        </Box>
    );
};

DetailHeadInfo.propTypes = {
    product: shape({}).isRequired,
    isDesktop: bool.isRequired,
};

export default DetailHeadInfo;
