import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { permissionsSelectors } from "reducers/administration";
import { permissionsActions } from "reducers/administration/simple";

import PermissionsConfirm from "pages/administration/_components/PermissionsConfirm";

const mapStateToProps = (state) => ({
    user: permissionsSelectors.getUser(state),
    permissions: permissionsSelectors.getFuturePermissions(state),
    credentialGroups: permissionsSelectors.getCredentialGroups(state),
    fetching: permissionsSelectors.isFetching(state),
    idActivity: permissionsSelectors.getIdActivity(state) ?? "administration.simple.modify.permissions.send",
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(permissionsActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsConfirm);
