import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import {
    getNumberRender,
    PDFAmountField,
    PDFAmountFieldError,
    PDFBeneficiaryField,
    PDFProductSelectorField,
    PDFTextField,
} from "pages/forms/customForms/PDFTicket";
import BeneficiaryField from "pages/forms/customForms/_customFields/BeneficiaryField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import FormattedAmount from "pages/_components/FormattedAmount";
import { bool, func, shape, string } from "prop-types";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as configUtils from "util/config";
import { formatNumber } from "util/format";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as schedulerUtils from "util/scheduler";
import * as thirdTransaction from "util/thirdTransaction";
import Text from "pages/_components/Text";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import { statusMap } from "util/general";
import { validateTransferLocalForm } from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { selectors as sessionSelectors } from "reducers/session";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import FormTransition from "../_components/FormTransition";
import { SCHEDULER_DATE } from "../_components/_fields/ReadText";
import { useStartSensorDataCapture } from "./hooks/useStartSensorDataCapture";

const BANESCO_BANK_CODE = "BANSPAPA";

const TransferLocalForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        previewData,
        isTablet,
        transaction,
        data,
        fromOneTouchAccess,
        activeEnvironment,
        achAccountValidationResult,
    } = props;
    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);
    const { idActivity } = transaction;
    const [forex, setForex] = useState(false);
    const [simplifiedAccountDetails, setSimplifiedAccountDetails] = useState({
        showSimplifiedAccountDisclaimer: false,
        currency: null,
        monthAvailBalance: null,
    });
    const [displayForex, setDisplayForex] = useState(true);

    const { decimalSeparator, thousandSeparator } = numberFormat();

    const isCreditCardPaymentTicket = idActivity === "creditCard.payment.creditCardThird.send";
    const isCreditCardRechargeTicket = idActivity === "creditCard.recharge.creditCardThird.send";

    const ID_FORM =
        isCreditCardPaymentTicket || isCreditCardRechargeTicket
            ? thirdTransaction.getThirdTransactionId(idActivity)
            : thirdTransaction.getThirdTransactionId(location.pathname);
    const transactionKind = thirdTransaction.getTransactionKindByIdForm(ID_FORM);

    const TITLE_FORM =
        isCreditCardPaymentTicket || isCreditCardRechargeTicket
            ? thirdTransaction.getThirdTransactionTitle(idActivity)
            : thirdTransaction.getThirdTransactionTitle(location.pathname);

    const TITLE_PREVIEW_VIEW_FORM =
        isCreditCardPaymentTicket || isCreditCardRechargeTicket
            ? thirdTransaction.getThirdTransactionTitle(idActivity)
            : thirdTransaction.getThirdTransactionTitle(location.pathname);

    const ID_ACTIVITY = `${ID_FORM}.send`;
    const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

    const isCreditCardPaymentForm = location.pathname === "/formCustom/payCreditCardThird";
    const isCreditCardRechargeForm = location.pathname === "/formCustom/rechargeCreditCardThird";

    useStartSensorDataCapture(mode);

    const [titleForm, setTitleForm] = useState(TITLE_FORM);
    const [idActivityState, setIdActivityState] = useState(transaction?.idActivity ? idActivity : `${ID_FORM}.send`);

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
            setTitleForm(TITLE_FORM);
        }
        if (mode === "preview" || mode === "view") {
            setTitleForm(TITLE_PREVIEW_VIEW_FORM);
        }

        if (mode === "preview") {
            if (previewData.beneficiaryNameAlreadyExists) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("forms.transfers.local.beneficiary.nameAlreadyExists"),
                        "warning",
                        ["form"],
                    ),
                );
            }

            if (previewData.beneficiaryAlreadyExists) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("forms.transfers.local.beneficiary.alreadyExists"),
                        "warning",
                        ["form"],
                    ),
                );
            }
        }
        // eslint-disable-next-line
    }, [dispatch, mode, location]);

    const hideACHModal = () => {
        dispatch(formActions.hideACHModal());
    };

    const returnToTransferPre = () => {
        dispatch(formActions.hideACHModal());
        dispatch(formActions.goBackCleanTransaction());
    };

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        const vaucherLabel = idStatus === "REJECTED" ? "Reference" : "";
        const showReference = idStatus === "FINISHED" || idStatus === "REJECTED";

        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                )}

                {showReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.transfers.local.voucher${vaucherLabel}.label`)}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={i18n.get("forms.transfers.local.date.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={`${
                        transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                    } ${i18n.get(thirdTransaction.getTransactionOperationlabel(idActivity))}`}
                    label={i18n.get("forms.transfers.local.operationType.label")}
                />

                <PDFProductSelectorField idForm={ID_FORM} name="debitAccount" values={values} />

                <PDFBeneficiaryField idForm={ID_FORM} name="beneficiary" values={values} />

                {values.estimatedDebit && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="estimatedDebit"
                        value={`${values.estimatedDebit?.currency} ${getNumberRender({
                            quantity: values.estimatedDebit?.quantity,
                        })}`}
                    />
                )}

                {values.estimatedCredit && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="estimatedCredit"
                        value={`${values.estimatedCredit?.currency} ${getNumberRender({
                            quantity: values.estimatedCredit?.quantity,
                        })}`}
                    />
                )}

                {values._rate && <PDFTextField idForm={ID_FORM} value={values._rate} name="rate" />}
                {!isCreditCardPaymentForm && values.reason && (
                    <PDFTextField idForm={ID_FORM} name="reason" values={values} />
                )}
            </>
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler) => {
        const { currentLang, fromBackoffice, isDesktop, isACHModalVisible } = props;

        const idTransaction = transaction?.idTransaction;
        const debitAccountOrigin = parse(location.search)?.debitAccount;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        if (mode === "edit" && (!preDataForm || !preDataForm.currencyList || !preDataForm.debitAccountList)) {
            return <PageLoading loading />;
        }
        const preData = preDataForm || {
            currencyList: [],
            debitAccountList: [],
        };

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: preData.currencyList?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })) || [
                {
                    id: values?.amount?.currency,
                    label: values?.amount?.currency ? i18n.get(`currency.label.${values?.amount?.currency}`) : "",
                },
            ],
        };
        let debitAccountListOptions = {
            options: preData?.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };
        if (!debitAccountListOptions || !debitAccountListOptions.options || !debitAccountListOptions.options.length) {
            debitAccountListOptions = {
                options: [
                    {
                        id: data.debitAccountData?.idProduct,
                        label: data.debitAccountData?.otherLabel,
                        productType: data.debitAccountData?.productType,
                    },
                ],
            };
        }
        const getDataAmount = (amount) => ({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: [
                {
                    id: amount?.currency,
                    label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
                },
            ],
        });

        const getProductTypeList = () => {
            const { beneficiary } = values;
            if (beneficiary?.bank?.id === BANESCO_BANK_CODE) {
                return [
                    { id: "CA", label: i18n.get("forms.transfers.local.productType.banesco.CA") },
                    { id: "PR", label: i18n.get("forms.transfers.local.productType.banesco.PR") },
                    { id: "TC", label: i18n.get("forms.transfers.local.productType.banesco.TC") },
                    { id: "TP", label: i18n.get("forms.transfers.local.productType.banesco.TP") },
                ];
            }
            return [
                { id: "CA", label: i18n.get("forms.transfers.local.productType.other.CA") },
                { id: "CC", label: i18n.get("forms.transfers.local.productType.other.CC") },
                { id: "TC", label: i18n.get("forms.transfers.local.productType.other.TC") },
                { id: "PR", label: i18n.get("forms.transfers.local.productType.other.PR") },
            ];
        };

        const getBankList = () => {
            const { debitAccount } = values;
            const debitAccountData = preData?.debitAccountList?.find((da) => da.idProduct === debitAccount);

            if (debitAccountData?.simplified && debitAccountData?.simplifiedInternacional) {
                return preData?.bankList.filter((b) => b.id === BANESCO_BANK_CODE) || [];
            }

            return preData?.bankList || [];
        };

        const cleanScheduler = (debitAccount, amountCurrency) => {
            const debitAccountData = preData?.debitAccountList?.find((da) => da.idProduct === debitAccount);
            if (debitAccountData?.currency !== amountCurrency) {
                setFieldValue("scheduler", null);
            }
        };

        const onDebitAccountChange = (debitAccount) => {
            const { beneficiary, amount } = values;
            const debitAccountData = preData?.debitAccountList?.find((da) => da.idProduct === debitAccount);
            if (debitAccountData?.simplified) {
                if (debitAccountData?.simplifiedInternacional) {
                    const banescoBank = preData?.bankList.find((b) => b.id === BANESCO_BANK_CODE);
                    setFieldValue("beneficiary", { ...beneficiary, bank: banescoBank });
                }
                setSimplifiedAccountDetails({
                    showSimplifiedAccountDisclaimer: true,
                    currency: debitAccountData.currency,
                    monthAvailBalance: debitAccountData.monthAvailBalance,
                });
                setDisplayForex(false);
            } else {
                setSimplifiedAccountDetails({
                    showSimplifiedAccountDisclaimer: false,
                    currency: null,
                    monthAvailBalance: null,
                });
                setDisplayForex(true);
            }
            if (!forex || !amount?.currency) {
                setFieldValue("amount", { ...(amount || {}), currency: debitAccountData.currency });
            }
            cleanScheduler(debitAccount, amount?.currency);
            setFieldValue("forex", forex);
        };

        const onBeneficiaryChange = (newValue) => {
            const { debitAccount, amount } = values;
            const bankNameID = newValue.bank?.id || newValue.bankCode;

            if (isCreditCardPaymentForm || isCreditCardRechargeForm) {
                setFieldValue("amount", { ...(amount || {}), currency: "USD" });
            } else if (!amount) {
                setFieldValue("amount", { currency: "USD", quantity: "" });
            }

            cleanScheduler(debitAccount, "USD");
            if (ID_FORM === "transfers.local") {
                if (bankNameID === "BANSPAPA") {
                    setIdActivityState("transfers.thirdParties.send");
                } else {
                    setIdActivityState(ID_ACTIVITY);
                }
            }
        };

        const onAmountChange = (amount) => {
            const { debitAccount } = values;
            cleanScheduler(debitAccount, amount?.currency);
        };

        const isSameCurrency = () => {
            const { debitAccount, amount } = values;

            if (debitAccount && amount?.currency) {
                const debitAccountData = preData?.debitAccountList?.find((da) => da.idProduct === debitAccount);
                return debitAccountData?.currency === amount?.currency;
            }
            return false;
        };

        const handleToggleForex = () => {
            if (forex) {
                setFieldValue("amount", { ...(values?.amount || {}), currency: values?.debitAccountData?.currency });
            }
            setFieldValue("forex", !forex);
            setForex(!forex);
        };

        const estimatedDebit = previewData?.estimatedDebit || values?._estimatedDebit;
        const estimatedCredit = previewData?.estimatedCredit || values?._estimatedCredit;
        const exchangeRate = previewData?.exchangeRate
            ? `1.00 ${previewData.exchangeRate?.currency} = ${formatNumber(previewData.exchangeRate?.quantity, 2, 2)} ${
                  previewData.exchangeRate?.currency === "EUR" ? "USD" : "EUR"
              }`
            : values?._rate;

        return (
            <>
                {mode === "edit" && (
                    <Row
                        {...(isTablet && { gapX: "8" })}
                        {...(isDesktop && !isTablet && { gapX: "12" })}
                        {...(!isDesktop && { gapY: "0" })}>
                        <Col xs={12} md={6}>
                            <Box
                                background="white"
                                position="relative"
                                className={classNames({
                                    "pl-5 pl-lg-10 pr-5 pr-lg-10 pt-6 pb-11 pb-md-11 mx-n-5 mb-7":
                                        isCreditCardPaymentForm || isCreditCardRechargeForm,
                                    "pl-5 pl-lg-10 pr-5 pr-lg-10 pb-9 pb-md-10 mx-n-5 mb-7":
                                        !isCreditCardPaymentForm && !isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={debitAccountListOptions}
                                    key="debitAccount"
                                    name="debitAccount"
                                    renderAs="combo"
                                    value={values?.debitAccount || debitAccountOrigin}
                                    idField="debitAccount"
                                    labelIdField="react-select-debitAccount-input"
                                    onChange={onDebitAccountChange}
                                    autoFocus
                                    loadBalance
                                />
                                {/* TODO */}
                                {simplifiedAccountDetails.showSimplifiedAccountDisclaimer && (
                                    <Box
                                        display="flex"
                                        alignX="flex-end"
                                        {...(isDesktop
                                            ? { position: "absolute", bottom: "5", right: "10" }
                                            : { className: "mt-3" })}>
                                        <Text
                                            color="secondary-hover-color"
                                            size="6"
                                            align="rigth"
                                            uppercase
                                            labelKey="transfer.local.simplifiedAccount.disclaimer"
                                        />
                                        <FormattedAmount
                                            size="6"
                                            className="pl-1"
                                            color="secondary-hover-color"
                                            quantity={simplifiedAccountDetails.monthAvailBalance}
                                            currency={simplifiedAccountDetails.currency}
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Box
                                background="white"
                                className={classNames({
                                    "pt-10 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-11 pb-md-11 mx-n-5 mb-7":
                                        isCreditCardPaymentForm || isCreditCardRechargeForm,
                                    "pt-6 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-11 mx-n-5 mb-7":
                                        !isCreditCardPaymentForm && !isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                <Box
                                    display="flex"
                                    gap={isDesktop ? "3" : "2"}
                                    {...(isDesktop ? { alignY: "center" } : { column: true, alignX: true })}>
                                    {!isCreditCardPaymentForm && !isCreditCardRechargeForm && displayForex && (
                                        <Box display="flex" {...(!isDesktop && { className: "align-items-start" })}>
                                            <SwitchToggle
                                                {...(isDesktop && { className: "mt-7" })}
                                                textClassName="ml-3 mr-0"
                                                switchIsOn={forex}
                                                fixLabelKey="forex.label"
                                                color="text"
                                                size={isDesktop ? "5" : "3"}
                                                removeMinHeight
                                                handleToggle={handleToggleForex}
                                                tooltipText="forex.tooltip"
                                            />
                                        </Box>
                                    )}
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Amount}
                                        data={dataAmount}
                                        key="amount"
                                        name="amount"
                                        value={
                                            isCreditCardRechargeForm
                                                ? { currency: "USD", quantity: "" }
                                                : { currency: values?.debitAccountData?.currency, quantity: "" }
                                        }
                                        idField="amount"
                                        customPlaceholderCurrency=""
                                        customPlaceholderQuantity="0.00"
                                        quantityTextAlign="text-left"
                                        disabledCurrency={!forex}
                                        onChange={onAmountChange}
                                    />
                                </Box>

                                {!isCreditCardPaymentForm && !isCreditCardRechargeForm && (
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Text}
                                        key="reason"
                                        name="reason"
                                        idField="reason"
                                        isRequired={false}
                                        value={values?.reason}
                                        validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                        maxLength={configUtils.getInteger(
                                            "form.transfers.local.field.reason.maxLength",
                                        )}
                                    />
                                )}
                            </Box>

                            <Box
                                background="white"
                                className={classNames({
                                    "pt-0 pb-0 pt-md-5 pb-md-5": isCreditCardPaymentForm || isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                {renderScheduler && renderScheduler(!isSameCurrency() || forex)}
                            </Box>
                        </Col>

                        <Box
                            className={classNames({
                                "center-absolute-xy arrow-transfer": isDesktop,
                                "arrow-transfer": !isDesktop,
                            })}
                            {...(isDesktop && { position: "absolute", left: "50", top: "50" })}>
                            <Image src="images/icons/transfersArrow.svg" />
                        </Box>

                        <Col xs={12} md={6}>
                            <Field
                                {...genericProps}
                                isLocalForm
                                component={BeneficiaryField}
                                key="beneficiary"
                                name="beneficiary"
                                idField="beneificary"
                                bankList={getBankList()}
                                productTypeList={getProductTypeList()}
                                value={values?.beneficiary}
                                onChange={onBeneficiaryChange}
                                validateBankPermissions
                            />
                        </Col>
                    </Row>
                )}

                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount(values?.amount)}
                                key="amount"
                                name="amount"
                                value={values?.amount}
                                idField="amount"
                                bigDataAmount
                                label_viewMap={
                                    transaction?.idTransactionStatus === "FINISHED"
                                        ? { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`) }
                                        : { [currentLang]: i18n.get("forms.transfers.local.amount.label") }
                                }
                            />
                        </Box>
                        {TooltipProcessingComponent}
                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {(transaction?.data?.backendReference || values?.backendReference) && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.backendReference || values?.backendReference}
                                    label={
                                        transaction?.idTransactionStatus === "PROCESSING" ||
                                        transaction?.idTransactionStatus === "REJECTED"
                                            ? "forms.transfers.local.voucherReference.label"
                                            : "forms.transfers.local.voucher.label"
                                    }
                                    shouldRender={
                                        mode === "view" &&
                                        (transaction?.data?.backendReference || values?.backendReference)
                                    }
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === "preview"
                                        ? moment().format("DD/MM/YYYY")
                                        : transaction?.idParentTransaction
                                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                                }
                                label="forms.transfers.local.date.label"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={`${
                                    transaction?.programed
                                        ? i18n.get("forms.transaction.ticket.typeOperation.program")
                                        : ""
                                }
                                    ${i18n.get(thirdTransaction.getTransactionOperationlabel(idActivity))}`}
                                label={
                                    isCreditCardPaymentTicket || isCreditCardRechargeTicket
                                        ? "forms.creditCard.payment.typeOperation.label"
                                        : "forms.transfers.local.operationType.label"
                                }
                                shouldRender={mode === "view"}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={mode === "preview" ? debitAccountListOptions : { options: [] }}
                                key="debitAccount"
                                name="debitAccount"
                                renderAs="combo"
                                value={values?.debitAccount}
                                idField="debitAccount"
                            />

                            <Field
                                {...genericProps}
                                isLocalForm
                                component={BeneficiaryField}
                                key="beneficiary"
                                name="beneficiary"
                                idField="beneificary"
                                value={values?.beneficiary}
                            />

                            {estimatedDebit && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={getDataAmount(estimatedDebit)}
                                    key="estimatedDebit"
                                    name="estimatedDebit"
                                    idField="estimatedDebit"
                                    value={estimatedDebit}
                                />
                            )}

                            {estimatedCredit && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={getDataAmount(estimatedDebit)}
                                    key="estimatedCredit"
                                    name="estimatedCredit"
                                    idField="estimatedCredit"
                                    value={estimatedCredit}
                                />
                            )}

                            {exchangeRate && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="rate"
                                    name="rate"
                                    idField="rate"
                                    value={exchangeRate}
                                />
                            )}

                            {!isCreditCardPaymentForm && !isCreditCardRechargeForm && values?.reason && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="reason"
                                    name="reason"
                                    idField="reason"
                                    value={values?.reason}
                                />
                            )}
                            {(!values?.scheduler || values?.scheduler?.selectedOption === schedulerUtils.TODAY) &&
                                mode === "preview" && (
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={i18n.get(`scheduler.immediate.radiobutton.label.${transactionKind}`)}
                                        label={thirdTransaction.getTransactionPayLabel(location.pathname)}
                                    />
                                )}
                            {values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type={SCHEDULER_DATE}
                                    value={values?.scheduler}
                                    label={thirdTransaction.getTransactionSchedulerLabel(location.pathname)}
                                />
                            ) : null}
                        </Box>
                        {isACHModalVisible && achAccountValidationResult?.message && (
                            <ConfirmDialog
                                showDialog={isACHModalVisible}
                                description={achAccountValidationResult?.message}
                                onConfirm={() => {
                                    hideACHModal();
                                }}
                                onCancel={() => {
                                    returnToTransferPre();
                                }}
                                modalId="transfer.local.ach.modal"
                                loading={false}
                            />
                        )}
                    </>
                ) : null}
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
        // eslint-disable-next-line react/destructuring-assignment
    } = props.preData || {};

    const getTopDisclaimer = () => {
        if (!mode || mode !== "preview" || !previewData) {
            return undefined;
        }

        if (
            previewData?.beneficiary?.bank?.id &&
            previewData.beneficiary.bank.id === BANESCO_BANK_CODE &&
            (previewData.estimatedDebit || previewData.estimatedCredit)
        ) {
            return "forms.transfers.local.banesco.rate.disclaimer";
        }

        if (previewData?.beneficiary?.bank?.id && previewData.beneficiary.bank.id !== BANESCO_BANK_CODE) {
            return "forms.transfers.local.otherBank.rate.disclaimer";
        }

        return undefined;
    };

    const validateForm = (values) => {
        const messageRquired = i18n.get(DEFAULT_REQUIRED);

        const errors = validateTransferLocalForm(
            values,
            messageRquired,
            i18n.get("administration.users.emailIncorrectFormat"),
            i18n.get("administration.users.numberMaxLengthExceeded"),
            i18n.get("beneficiary.create.validation.creditCard.notFound"),
        );

        const { amount, debitAccount } = values;
        if (!amount) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }
        const { quantity, currency } = amount;
        if (!quantity || quantity === "" || !currency || currency === "") {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }

        if (!debitAccount || debitAccount === "") {
            errors.debitAccount = messageRquired;
        }

        return errors;
    };

    const formProps = {
        title: titleForm,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: true,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: idActivityState,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        validate: validateForm,
        topDisclaimer: getTopDisclaimer(),
        showSchedulerMessage: false,
        pathBackEditMode: fromOneTouchAccess !== undefined && fromOneTouchAccess === true ? "/desktop" : undefined,
        showLocalRecharge: !(activeEnvironment.type === "corporate" && isCreditCardRechargeForm),
        disableSubmitButton: achAccountValidationResult?.snackbar && !achAccountValidationResult?.validAccount,
    };

    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    achAccountValidationResult: formSelectors.getAchAccountValidationResult(state),
    isACHModalVisible: formSelectors.isACHModalVisible(state),
    isSavedBeneficiarySelected: formSelectors.isSavedBeneficiarySelected(state),
});

TransferLocalForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    usesJointAccount: bool,
    data: shape({}),
    preData: shape({}),
    fromOneTouchAccess: bool,
    activeEnvironment: shape({
        type: string,
    }),
    achAccountValidationResult: shape({}),
    isACHModalVisible: bool,
    isSavedBeneficiarySelected: bool,
};
TransferLocalForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    usesJointAccount: false,
    data: {},
    preData: {},
    fromOneTouchAccess: false,
    activeEnvironment: {},
    achAccountValidationResult: {},
    isACHModalVisible: false,
    isSavedBeneficiarySelected: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TransferLocalForm));
